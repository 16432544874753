import HTTP from "@/api/index.js";
import { defineStore } from 'pinia'
// Temporality adding for logIn action

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useSearchStore = defineStore('search', {
  // arrow function recommended for full type inference
  state: () => ({
    minFromDate: "",
    defaultFromDate: "",
    maxFromDate: "",
    showModalApplyHousing: false,
    referencesFrankfurt: [],
    results: [],
    allReferences: [
      {
        id: "1",
        place: "Frankfurt School of Finance and Management",
        coord: [50.1329, 8.68022],
      },
      {
        id: "2",
        place: "Frankfurt University of Applied Sciences",
        coord: [50.13058, 8.69249],
      },
      {
        id: "3",
        place: "Frankfurt University of Music and Performing Arts",
        coord: [50.12009, 8.67603],
      },
      {
        id: "4",
        place: "Johann-Wolfgang-Goethe Universität - Campus Westend",
        coord: [50.1272, 8.6673],
      },
      {
        id: "5",
        place: "Johann-Wolfgang-Goethe Universität - Campus Riedberg",
        coord: [50.1742, 8.63],
      },
      { id: "6", place: "Dr. Hoch's Konservatorium", coord: [50.1099326, 8.6994727] },
      {
        id: "7",
        place: "Philosophisch-Theologische Hochschule St. Georgen",
        coord: [50.09773, 8.71233],
      },
      {
        id: "8",
        place: "Allianz Global Investors",
        coord: [50.1179, 8.66423],
      },
      {
        id: "9",
        place: "European Central Bank",
        coord: [50.1096, 8.70316],
      },
      {
        id: "10",
        place: "Allianz Commercial (Allianz Global Corporate and Specialty SE)",
        coord: [50.09828, 8.66546],
      },
      {
        id: "11",
        place: "UBS Europe SE - Niederlassung Frankfurt",
        coord: [50.11613, 8.67013],
      },
      {
        id: "12",
        place: "VISA Deutschland",
        coord: [50.11316, 8.67187],
      },
    ],
    // User's search selections is saved here to call API after
    searchParams: {
      reference: "",
      meanTransportation: "",
      travelTime: "",
      housingType: "",
      maxRent: 0,
    },
    filteredHousingAvailable: [],
    cheapestHousing: [],
    // Populates macro and micro map
    referenceCoord: [],
    zoomMacroMap: 12,
    avgRent: 0,
    marketingReference: "Frankfurt School of Finance and Management",
    marketingReferenceCoord: [],
    allAverages: [],
    // Search Pro form
    searchPro: {
      reference: "",
      referenceCoord: "",
      meanTransportation: "",
      travelTime: "",
      housingType: "",
      maxRent: 0,
      walkingTimeGym: "10",
      walkingTimeCinema: "25",
      walkingTimePharmacy: "5",
      walkingTimeSupermarket: "10",
      walkingcTimeClosestTrainStation: "15",
    },
  }),
  persist: true,
  getters: {
    enableSearchButton() {
      if (this.searchParams.meanTransportation != "" && this.searchParams.housingType != "" && this.searchParams.maxRent != "" && this.searchParams.reference != "" && this.searchParams.travelTime != "") {
        return false
      } else {
        return true
      }
    },
    valueReference() {
      return this.searchParams.reference
    },
    valueMeanTransportation() {
      return this.searchParams.meanTransportation
    },
    valueTravelTime() {
      return this.searchParams.travelTime
    },
    valueHousingType() {
      return this.searchParams.housingType
    },
    valueMaxRent() {
      return this.searchParams.maxRent
    },
    adSearchResultCount() {
      return this.filteredHousingAvailable.length
    },
    // Search Pro Form
    enableNextButtonPro() {
      if (this.searchPro.meanTransportation != "" && this.searchPro.housingType != "" && this.searchPro.maxRent != "" && this.searchPro.reference != "" && this.searchPro.travelTime != "") {
        return false
      } else {
        return true
      }
    },
    valueReferencePro() {
      return this.searchPro.reference
    },
    valueMeanTransportationPro() {
      return this.searchPro.meanTransportation
    },
    valueTravelTimePro() {
      return this.searchPro.travelTime
    },
    valueHousingTypePro() {
      return this.searchPro.housingType
    },
    valueMaxRentPro() {
      return this.searchPro.maxRent
    },
    // optionals
    valueClosestTrainStation() {
      return this.searchPro.walkingcTimeClosestTrainStation
    },
    valueGym() {
      return this.searchPro.walkingTimeGym
    },
    valueSupermarket() {
      return this.searchPro.walkingTimeSupermarket
    },
    valuePharmacy() {
      return this.searchPro.walkingTimePharmacy
    },
    valueCinema() {
      return this.searchPro.walkingTimeCinema
    },
  },
  actions: {
    storeReference(reference) {
      this.searchParams.reference = reference
    },
    storeReferenceCoords(coord) {
      this.referenceCoord = [coord.lat, coord.lng]
    },
    storeTravelTime(travelTime) {
      this.searchParams.travelTime = travelTime
    },
    storeMeanTransportation(meanTransportation) {
      this.searchParams.meanTransportation = meanTransportation
    },
    storeHousingType(housingType) {
      this.searchParams.housingType = housingType
    },
    storeMaxRent(maxRent) {
      this.searchParams.maxRent = maxRent.detail.value
    },
    // Store Search Pro
    storeReferencePro(reference) {
      this.searchPro.reference = reference
    },
    storeReferenceCoordsPro(coord) {
      this.searchPro.referenceCoord = coord
    },
    storeTravelTimePro(travelTime) {
      this.searchPro.travelTime = travelTime
    },
    storeMeanTransportationPro(meanTransportation) {
      this.searchPro.meanTransportation = meanTransportation
    },
    storeHousingTypePro(housingType) {
      this.searchPro.housingType = housingType
    },
    storeMaxRentPro(maxRent) {
      this.searchPro.maxRent = maxRent.detail.value
    },
    // Update Availability FROM
    getFromDate() {
      const today = new Date();

      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const day = String(today.getDate()).padStart(2, "0");
      const hours = String(today.getHours()).padStart(2, "0");
      const minutes = String(today.getMinutes()).padStart(2, "0");
      const seconds = String(today.getSeconds()).padStart(2, "0");

      const todayDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

      this.defaultFromDate = todayDateTime;
      this.minFromDate = todayDateTime;
    },
    getFromMaxDate() {
      const today = new Date();

      // Add 5 years to the current year
      today.setFullYear(today.getFullYear() + 5); // 5: 5 year offser

      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const day = String(today.getDate()).padStart(2, "0");
      const hours = String(today.getHours()).padStart(2, "0");
      const minutes = String(today.getMinutes()).padStart(2, "0");
      const seconds = String(today.getSeconds()).padStart(2, "0");

      const todayDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

      this.maxFromDate = todayDateTime;
    },
    // Update Availability FROM
    availableFrom(ev) {
      // console.log(ev.detail.value)
      this.defaultFromDate = ev.detail.value;
      // console.log(ev.detail.value)
    },
    // Optionals
    storeClosestTrainStationTime(ClosestTrainStationTime) {
      this.searchPro.walkingcTimeClosestTrainStation = ClosestTrainStationTime
    },
    storeGymTime(GymTime) {
      this.searchPro.walkingTimeGym = GymTime
    },
    storeSupermarketTime(SupermarketTime) {
      this.searchPro.walkingTimeSupermarket = SupermarketTime
    },
    storePharmacyTime(PharmacyTime) {
      this.searchPro.walkingTimePharmacy = PharmacyTime
    },
    storeCinemaTime(CinemaTime) {
      this.searchPro.walkingTimeCinema = CinemaTime
    },
    async getCheapestAds() {
      const cheapest = await HTTP.get("/cheapest/", {
        headers: {
          'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
        }
      });
      const averages = await HTTP.get("/marketing/", {
        headers: {
          'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
        }
      });

      // Storing all the averages in case the user changes the ion-segment selection later.
      this.allAverages = averages.data
      // GET THE AVERAGE RENT FOR FRANKFURT SCHOOL (created)
      // Getting the average for Frankfurt School
      const foundAvg = averages.data.find(item => item.reference === this.marketingReference);
      // If the object is found, extract its average; otherwise, assign a default value
      const averageValue = foundAvg ? foundAvg.average : 0;
      this.avgRent = averageValue

      //  Obtaining the reference coordinates
      const placeToMatch = "Frankfurt School of Finance and Management";
      for (const reference of this.allReferences) {
        if (reference.place === placeToMatch) {
          this.marketingReferenceCoord = reference.coord;
          break;
        }
      }

      // UPDATE CHEAPEST ADS WITH THE AVERAGE AND CALCULATE THE SAVINGS PER YEAR
      // Iterate over average and update cheapest
      cheapest.data.forEach(item1 => {
        averages.data.forEach(item2 => {
          // Find the corresponding object in cheapest.references
          const matchingObject = item1.references.find(item1 => item1.referenceName === item2.reference);
          // If a match is found, add the "average" value
          if (matchingObject) {
            matchingObject.value = item2.average;
            // Calculate savingsPerYear and add it to the reference object
            matchingObject.savingsPerYear = (item2.average - item1.totalRent) * 12;
          }
        });
      });
      this.cheapestHousing = cheapest.data
    },
    async searchHousing() {
      const housing = await HTTP.get("/housing/?meanTransportation=" + this.searchParams.meanTransportation + "&travelTime=" + Number(this.searchParams.travelTime) + "&housingType=" + this.searchParams.housingType + "&totalRent=" + Number(this.searchParams.maxRent) + "&reference=" + this.searchParams.reference, {
        headers: {
          'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
        }
      });
      this.filteredHousingAvailable = housing.data
      // setTimeout(() => {
      //   // Reload the current page
      //   location.reload();
      // }, 250); // 1000 milliseconds = 1 second
      // // console.log(this.searchParams.travelTime);
      // const travelTime = Number(this.searchParams.travelTime)
      // // console.log(travelTime);
      // if (travelTime <= 15) {
      //   this.filteredHousingAvailable = this.housingAvailable.filter(i => Number(i.trainTime) <= 15);
      // } else if (travelTime <= 30) {
      //   this.filteredHousingAvailable = this.housingAvailable.filter(i => Number(i.trainTime) <= 30);
      // } else if (travelTime <= 45) {
      //   this.filteredHousingAvailable = this.housingAvailable.filter(i => Number(i.trainTime) <= 45);
      // } else {
      //   this.filteredHousingAvailable = this.housingAvailable.filter(i => Number(i.trainTime) <= 60);
      // }
      // // console.log(this.filteredHousingAvailable);
    },
    sortAds(ev) {
      const sortCondition = ev.detail.value
      if (sortCondition === "totalRent") {
        this.filteredHousingAvailable = this.filteredHousingAvailable.sort((a, b) => a['totalRent'] - b['totalRent']);
      } else {
        this.filteredHousingAvailable = this.filteredHousingAvailable.sort((a, b) => {
          const valueA = Number(a.references.find(ref => ref.referenceName === this.searchParams.reference)?.meanTransportationAndTime.find(item => item.meanTransportation === sortCondition)?.value) || 0;
          const valueB = Number(b.references.find(ref => ref.referenceName === this.searchParams.reference)?.meanTransportationAndTime.find(item => item.meanTransportation === sortCondition)?.value) || 0;
          return valueA - valueB;
        });
      }
    },
    mktgChangeRef(ev) {
      this.marketingReference = ev.detail.value
      const foundAvg = this.allAverages.find(item => item.reference === this.marketingReference);
      // If the object is found, extract its average; otherwise, assign a default value
      const averageValue = foundAvg ? foundAvg.average : 0;
      this.avgRent = averageValue

      for (const reference of this.allReferences) {
        if (reference.place === this.marketingReference) {
          this.marketingReferenceCoord = reference.coord;
          break;
        }
      }
    },
    async storeAdvancedSearchPreferences(fbUserUid) {
      console.log(fbUserUid)
      if (this.searchPro.housingType != "" && this.searchPro.maxRent != "" && this.searchPro.reference != "" && this.searchPro.travelTime != "") {
        const advancedPreferences = {
          userIdentifier: fbUserUid,
          sendEmail: true,
          referenceName: this.searchPro.reference,
          referenceCoord: {
            lat: Number(this.searchPro.referenceCoord[0]),
            lng: Number(this.searchPro.referenceCoord[1]),
          },
          meanTransportation: this.searchPro.meanTransportation,
          travelTime: Number(this.searchPro.travelTime),
          housingType: this.searchPro.housingType,
          maxRent: this.searchPro.maxRent,
          availableFrom: this.formatDateBeforePushingToDB(this.defaultFromDate),
          walkingTimeGym: Number(this.searchPro.walkingTimeGym),
          walkingTimeCinema: Number(this.searchPro.walkingTimeCinema),
          walkingTimePharmacy: Number(this.searchPro.walkingTimePharmacy),
          walkingTimeSupermarket: Number(this.searchPro.walkingTimeSupermarket),
          walkingcTimeClosestTrainStation: Number(this.searchPro.walkingcTimeClosestTrainStation),
        };
        try {
          const response = await HTTP.post("/search-pro-preferences/", advancedPreferences, {
            headers: {
              'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
            }
          })
          if (response.status === 200) {
            // Reseting values in the form
            this.searchPro.reference = "",
              this.searchPro.referenceCoord = "",
              this.searchPro.meanTransportation = "",
              this.searchPro.travelTime = "",
              this.searchPro.housingType = "",
              this.searchPro.maxRent = 0,
              this.searchPro.walkingTimeGym = "10",
              this.searchPro.walkingTimeCinema = "25",
              this.searchPro.walkingTimePharmacy = "5",
              this.searchPro.walkingTimeSupermarket = "10",
              this.searchPro.walkingcTimeClosestTrainStation = "15",
              this.getFromDate(),
              this.getFromMaxDate()
          }
        } catch (error) {
          // this.showNetworkErrorAdAlert = true
          console.log(error)
        }
      }
    },
    formatDateBeforePushingToDB(inputDateString) {
      const inputDate = new Date(inputDateString);
      const day = inputDate.getDate().toString().padStart(2, '0');
      const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
      const year = inputDate.getFullYear().toString().slice(2);

      return `${day}.${month}.${year}`;
    },
    closeModalApplyHousing() {
      this.showModalApplyHousing = false
    },
    openApplyForm() {
      this.showModalApplyHousing = true
    },
    async getAllReferences() {
      const response = await HTTP.get("/references-frankfurt/", {
        headers: {
          'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
        }
      });
      this.referencesFrankfurt = response.data
      console.log(this.referencesFrankfurt)
    },
    handleChange(event) {
      // console.log(event.target.value);
      const query = event.target.value.toLowerCase();
      console.log(query);
      this.results = this.referencesFrankfurt.filter(
        (d) => d.reference.toLowerCase().indexOf(query) > -1
      );
    },
    resetValueReference() {
      console.log(this.searchParams.reference)
      this.searchParams.reference = ''
    }
  },
})