import { useAuthStore } from "@/store/auth";
// import { useAdminStore } from "@/store/admin";
// import { useSearchStore } from "@/store/search";
import { createRouter, createWebHistory } from '@ionic/vue-router';

const authCheck = (to) => {
  // const store = useSearchStore()
  // console.log(to)
  const store = useAuthStore();
  console.log("authCheck", store.isLoggedIn);
  if (store.isLoggedIn) {
    if (to.path === '/login') {
      return { path: '/housing-finder' };
    } else {
      return
    }
  } else {
    if (to.path === '/login') {
      return
    } else {
      return { path: '/login' };
    }
  }
};

// const adCheck = (to, from, next) => {
//   const adminStore = useAdminStore();
//   // Assuming `hasAd` is a property or a getter that tells us if the user already has an ad.
//   // You might need to adjust this part to fit how your store manages the `housingAvailable` array or equivalent.
//   const userHasAd = adminStore.hasAd;
//   if (to.path === '/new-ad' && userHasAd) {
//     // User is trying to access /new-ad but already has an ad, redirect them
//     next({ path: '/my-offers' });
//   } else {
//     return next();
//   }
// };


const routes = [
  {
    path: '/',
    component: () => import("../views/Legal/PageUnderConstruction.vue")
  },
  // Landing
  // {
  //   path: '/',
  //   component: () => import("../views/LandingSelect.vue")
  // },
  // views/Listings
  // {
  //   path: '/search',
  //   component: () => import("../views/Listings/LandingSearch.vue")
  // },
  // {
  //   path: '/search-result',
  //   component: () => import("../views/Listings/ListingsResults.vue")
  // },
  // {
  //   path: '/detail-housing/:housingId',
  //   component: () => import("../views/Listings/HousingDetail.vue")
  // },
  // {
  //   path: '/cheapest-housing-detail/:housingId',
  //   component: () => import("../views/Listings/MarketingAdsDetail.vue")
  // },
  // // // views/Admin
  // {
  //   path: '/my-offers',
  //   component: () => import("../views/Admin/MyOffers.vue"),
  //   beforeEnter: authCheck,
  // },
  // {
  //   path: '/edit-ad/:displayId',
  //   component: () => import("../views/Admin/EditAd.vue"),
  //   beforeEnter: authCheck,
  // },
  // {
  //   path: '/my-profile',
  //   component: () => import("../views/Admin/MyProfile.vue"),
  //   beforeEnter: authCheck,
  // },
  // {
  //   path: '/new-ad',
  //   component: () => import("../views/Admin/NewAd.vue"),
  //   beforeEnter: (to, from, next) => {
  //     authCheck(to); // Keep your existing auth checks
  //     adCheck(to, from, next); // Add the new ad check
  //   },
  // },
  // // views/Authentication
  {
    path: '/login',
    component: () => import("../views/Authentication/LoginPage.vue"),
    beforeEnter: authCheck,
  },
  // {
  //   path: '/create-account',
  //   component: () => import("../views/Authentication/CreateAccount.vue")
  // },
  // // SearchPro
  // {
  //   path: '/advanced-search',
  //   component: () => import("../views/SearchPro/SearchProForm.vue")
  // },
  // {
  //   path: '/advanced-search-edit',
  //   component: () => import("../views/Admin/SearchProFormEdit.vue"),
  //   beforeEnter: authCheck,
  // },
  // // Legal
  // {
  //   path: '/terms-and-conditions',
  //   component: () => import("../views/Legal/TermsAndConditions.vue")
  // },
  // {
  //   path: '/data-protection-information',
  //   component: () => import("../views/Legal/DataProtection.vue")
  // },
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/NotFound.vue"),
  },
  // {
  //   path: '/cheapest-housing-detail/:catchAll(.*)',
  //   component: () => import("../views/NotFound.vue")
  // },
  // INBOUND MARKETING
  {
    path: '/housing-finder',
    component: () => import("../views/InboundMarketing/InboundMarketingMain.vue"),
    beforeEnter: authCheck,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
