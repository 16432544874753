import { defineStore } from "pinia";
import {
  fbAuthStateListener,
  fbCreateAccount,
  fbGetUserProfile,
  fbSignIn,
  fbSignOut,
} from "./firebase";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    email: null,
    password: null,
    user: null,
    profile: null,
    error: null,
  }),
  persist: true,
  getters: {
    isLoggedIn() {
      return this.user !== null //&& this.user.emailVerified -> Commented because we want to make it easy for beta testing. Also did comment the email verification in store firebase.js line 103.
    },
    userError() {
      return this.error
    },
  },
  actions: {
    /**
     * listens for state changes, ie a user logging in or out
     * and if logging in, loading the associated profile info
     * @returns
     */
    initializeAuthListener() {
      return new Promise((resolve) => {
        fbAuthStateListener(async (user) => {
          this.user = user ? user : null;

          if (user) {
            const profile = (await fbGetUserProfile());
            this.profile = profile;
          }
          resolve(true);
        });
      });
    },
    /**
     * Logs in a user with the provided email and password
     * @param data
     */
    async logInUser() {
      try {
        const response = await fbSignIn(this.email, this.password);
        console.log(response)
        this.user = response.user ? response.user : null;
        this.error = null;
        // Cleaning email and password fields
        this.email = null;
        this.password = null;
        return true;
      } catch (e) {
        this.user = null;
        this.error = e;
        return false;
      }
    },
    /**
     * Logs out the current user
     * @param data
     */
    async logoutUser() {
      try {
        await fbSignOut();
        this.user = null;
        this.profile = null;
        this.error = null;
        return true;
      } catch (e) {
        this.error = e;
        return false;
      }
    },


    /**
     * Creates a new user account with the provided information
     * @param data
     */
    async createAccount() { // first, last taken out. Wont be using for now.
      try {
        const { user, profile } = await fbCreateAccount(this.email, this.password); //, first, last
        this.user = user ? user : null;
        this.profile = profile ? profile : null;
        this.error = null;
        // Cleaning password field
        this.password = null;
        return true;
      } catch (e) {
        this.user = null;
        this.error = e;
        return false;
      }
    },
    deletePasswordField() {
      this.password = null
    },
    resetError() {
      this.error = null
    },
    deleteError() {
      this.error = null
    }
  },
});